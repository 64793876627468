<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                dark
                :color="modalDataObject.color"
            >
                <v-toolbar-title>
                    {{modalDataObject.title}}
                </v-toolbar-title>
                <v-spacer />
                <v-btn
                    icon
                    dark
                    @click="closeMethod"
                >
                    <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-toolbar>
                <v-container fluid>
                    <v-row class="fill-width">
                        <v-container style="text-transform: none !important;">
                        <v-card color="grey lighten-4" class="d-flex flex-row align-center rounded-l-xl rounded-r-pill">

                            <div>
                                <v-sheet color="rgb(255, 0, 0, 0)" style="text-transform: none !important;" class="mx-6 my-2">
                                    <v-row dense justify="center" no-gutters>
                                        <div
                                            class="text-subtitle-2 transition-swing"
                                        >
                                            {{formattedWorkDateObject.month}}
                                        </div>
                                    </v-row>
                                    <v-row dense justify="center" no-gutters>
                                        <div
                                            class="text-h4 transition-swing  fill-height"
                                        >
                                            {{formattedWorkDateObject.day}}
                                        </div>
                                    </v-row>
                                    <v-row dense justify="center" no-gutters>
                                        <div
                                            class="text-subtitle-2 transition-swing"
                                        >
                                            {{formattedWorkDateObject.year}}
                                        </div>
                                    </v-row>
                                </v-sheet>
                            </div>
                            <div class="d-flex flex-column" style="z-index: 6;">
                                <v-sheet color="purple lighten-3" rounded="pill" class="d-flex flex-row justify-space-between py-2 px-4" v-if="overtimeMinutes > 0">
                                <v-icon left color="black">
                                    mdi-alarm-plus
                                </v-icon>
                                <v-spacer />
                                <div class="font-weight-medium">
                                    {{overtimeString}}
                                </div>
                                <v-spacer />
                                </v-sheet>
                                <v-sheet rounded="pill" class="text-center py-2 px-4" v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-alarm-off
                                </v-icon>
                                </v-sheet>
                                <v-spacer class="my-1"/>
                                <v-sheet color="amber accent-4" rounded="pill" class="d-flex flex-row justify-space-between py-2 px-4" v-if="isBusinessTrip">
                                <v-icon left color="black">
                                    mdi-bag-suitcase
                                </v-icon>
                                <v-spacer />
                                <div class="font-weight-medium">
                                    Trasferta
                                </div>
                                <v-spacer />
                                </v-sheet>
                                <v-sheet rounded="pill" class="text-center py-2 px-4" v-else>
                                <v-icon color="grey lighten-1">
                                    mdi-bag-suitcase-off
                                </v-icon>
                                </v-sheet>
                                
                            </div>
                            
                            <WorkDayProgress
                                :userWorkDay="proposedUWD"
                                :workDayTimes="workDayTimes"
                                :isoWorkDate="workDate"
                                :workDaySummary="workDaySummary"
                            />
                            
                            <v-btn
                                fab
                                large
                                class="ma-4"
                                dark
                                color="green lighten-2"
                                :disabled="!(dayTimeProgressObject.complete)"
                                :loading="fixLoading"
                                @click="sendUserWorkDayFix"
                            >
                                <v-icon>
                                mdi-content-save
                                </v-icon>
                            </v-btn>
                        </v-card>
                        </v-container>
                    </v-row>

                    <v-row>
                    <v-container fluid class="fill-height d-flex align-center justify-center">
                        <v-row v-if="proposedUWD.workDayRecords && proposedUWD.workDayRecords.length > 0" class="fill-heigth fill-width" style="display: flex; overflow-x: auto;">
                            <v-col cols="4" class="d-flex justify-center" 
                                v-for="workDayRecord in proposedUWD.workDayRecords"
                                :key="workDayRecord.dateIndex"
                            >
                                <TimeRecordCard 
                                    v-if="workDayRecord.recordType == 'CLOCK_RECORD'"
                                    :wdRecord="workDayRecord"
                                    :editable="true"
                                    :readOnly="fixLoading"
                                    :showNotes="false"
                                    v-on:edit-record-times="editTimeRecord(workDayRecord)"
                                />
                                <AbsenceRecordCard 
                                    v-if="workDayRecord.recordType == 'LEAVE_RECORD'"
                                    :wdRecord="workDayRecord"
                                    :readOnly="fixLoading"
                                    :showNotes="false"
                                    absenceType="LEAVE"
                                    v-on:absence-edit="editAbsenceRecord(workDayRecord)"
                                />
                                <AbsenceRecordCard 
                                    v-if="workDayRecord.recordType == 'HOLIDAY_RECORD'"
                                    :wdRecord="workDayRecord"
                                    :readOnly="fixLoading"
                                    :showNotes="false"
                                    absenceType="HOLIDAY"
                                    v-on:absence-edit="editAbsenceRecord(workDayRecord)"
                                    v-on:absence-delete="deletingRecord(workDayRecord.dateIndex)"
                                />
                                <SickRecordCard 
                                    v-if="workDayRecord.recordType == 'SICK_RECORD'"
                                    :wdRecord="workDayRecord"
                                    :readOnly="fixLoading"
                                    :showNotes="false"
                                    v-on:delete-sick-record="deletingRecord(workDayRecord.dateIndex)"
                                />
                            </v-col>

                        </v-row>
                        
                        <v-row v-else class="fill-heigth fill-width d-flex justify-center align-center mb-12">
                            <v-btn
                                fab
                                x-large
                                class="mr-4"
                                color="grey lighten-1"
                                @click="editTimeRecord(null)"
                            >
                                <v-icon>mdi-stamper</v-icon>
                            </v-btn>
                            <v-btn
                                fab
                                x-large
                                class="mr-4"
                                color="cyan accent-3"
                                :disabled="overtimeMinutes > 0 || isBusinessTrip"
                                @click="registerHolidayRecord(dayTimeProgressObject.scheduledTime)"
                            >
                                <v-icon>mdi-umbrella-beach-outline</v-icon>
                            </v-btn>
                            <v-btn
                                fab
                                x-large
                                class="mr-4"
                                color="teal accent-3"
                                @click="createAbsenceRecord(null)"
                            >
                                <v-icon>mdi-exit-run</v-icon>
                            </v-btn>
                            <v-btn
                                fab
                                x-large
                                :disabled="overtimeMinutes > 0 || isBusinessTrip"
                                color="pink lighten-3"
                                @click="registerSickRecord"
                            >
                                <v-icon>mdi-emoticon-sick-outline</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                    </v-row>
                </v-container>
                
           
            <v-speed-dial
                bottom
                right
                direction="top"
                :open-on-hover="false"
                transition="slide-y-reverse-transition"
                absolute
                fixed
                v-model="addButtonDial"
                :class="{disabled: isSickDay || isHolidayDay}"
                dark
                
            >
                <template v-slot:activator >
                    <v-btn
                    fab
                    x-large
                    :disabled="isHolidayDay || isSickDay || fixLoading"
                    >
                    <v-icon v-if="addButtonDial && !isSickDay && !isHolidayDay">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-plus
                    </v-icon>
                    </v-btn>
                </template>
                <v-btn v-if="isSickDay || isHolidayDay" v-show="false">
                </v-btn>
                <v-btn
                    fab
                    dark
                    @click="setBusinessTrip"
                    v-if="!isHolidayDay && !isSickDay"
                >
                    <v-icon v-if="isBusinessTrip">mdi-bag-suitcase-off</v-icon>
                    <v-icon v-else>mdi-bag-suitcase</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    @click="overtimeEditDialog = true"
                    v-if="!isHolidayDay && !isSickDay"
                >
                    <v-icon>mdi-alarm</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    @click="createAbsenceRecord"
                    v-if="!isHolidayDay && !isSickDay && !isEmptyUWD"
                >
                    <v-icon>mdi-exit-run</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    @click="registerSickRecord"
                    v-if="!(isBusinessTrip || overtimeMinutes > 0 || proposedUWD.workDayRecords.length > 0) && !isEmptyUWD"
                >
                    <v-icon>mdi-emoticon-sick</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    large
                    v-if="!isHolidayDay && !isSickDay && !isEmptyUWD"
                    @click="editTimeRecord(null)"
                >
                    <v-icon>mdi-stamper</v-icon>
                </v-btn>
            </v-speed-dial>
        </v-card>
        <TimeRecordEditDialog
            :show="timeRecordEditDialog"
            :record="editingRecord || {}"
            v-on:close-time-record-edit-dialog="timeRecordEditDialog = false"
            v-on:delete-time-record="deletingRecord"
            v-on:save-time-record="storeEditedTimeRecord"
            :dayRecordsDate="jsWorkDate"
            :forbiddenTimeRanges="clockRecordTimes"
        />
        <AbsenceRecordCreateDialog
            :show="absenceRecordCreateDialog"
            v-on:close-absence-record-edit-dialog="absenceRecordCreateDialog = false"
            :workDayScheduledTimes="workDayTimes"
            v-on:create-leave-record="registerLeaveRecord"
            v-on:create-holiday-record="registerHolidayRecord"
        />
        <AbsenceRecordEditDialog
            :show="absenceRecordEditDialog"
            :record="editingRecord || {}"
            v-on:close-absence-record-edit-dialog="absenceRecordEditDialog = false"
            v-on:delete-absence-record="deletingRecord"
            v-on:save-absence-record="updatingAbsenceRecord"
        />
        <OvertimeEditDialog
            :show="overtimeEditDialog"
            :overtimeMinutes="overtimeMinutes"
            v-on:close-overtime-edit-dialog="overtimeEditDialog = false"
            v-on:save-overtime="updateOvertime"
        />
        <ConfirmDialog
            :dialog="confirmDialog" 
            :dialogText="confirmDialogText"
            v-on:c-dialog-confirm="confirmDialogAction"
            v-on:c-dialog-cancel="confirmDialog = false"
        />
    </v-dialog>
</template>
<script>
export const TimeRecordEditDialog = () => import('../../dashboard/dialogs/TimeRecordEdit.vue')
export const AbsenceRecordCreateDialog = () => import('../../dashboard/dialogs/AbsenceRecordCreate.vue')
export const AbsenceRecordEditDialog = () => import('../../dashboard/dialogs/AbsenceRecordEdit.vue')
export const OvertimeEditDialog = () => import('../../dashboard/dialogs/OvertimeEdit.vue')

export const ConfirmDialog = () => import('../../global/ConfirmDialog.vue')

export const TimeRecordCard = () => import('../../dashboard/recordCards/TimeRecordCard.vue')
export const AbsenceRecordCard = () => import('../../dashboard/recordCards/AbsenceRecordCard.vue')
export const SickRecordCard = () => import('../../dashboard/recordCards/SickRecordCard.vue')
export const WorkDayProgress = () => import('../../global/WorkDayProgress.vue')

import consolidationRecordsApi from '../../../api/consolidationRecord'

import workTimesService from '../../../services/mixins/workTimesMixin'

import { DateTime } from 'luxon'

export default {
    data: function() {
        return {
            addButtonDial: false,
            timeRecordEditDialog: false,
            absenceRecordCreateDialog: false,
            absenceRecordEditDialog: false,
            overtimeEditDialog: false,
            editingRecord: {},
            proposedUWD: {
                workDayRecords: [],
            },
            deletingRecordIndex: null,
            confirmDialog: false,
            confirmDialogText: "",
            confirmDialogAction: () => {},
            timerProgressObject: {},
            fixLoading: false,
        }
    },
    mixins: [workTimesService],
    components: {
        TimeRecordCard,
        AbsenceRecordCard,
        SickRecordCard,
        WorkDayProgress,

        TimeRecordEditDialog,
        AbsenceRecordCreateDialog,
        AbsenceRecordEditDialog,
        OvertimeEditDialog,

        ConfirmDialog
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        userErrors: {
            type: Object,
            default: function() {
                return {}
            }
        },
        resolvingErrorIndex: Number
    },
    watch: {
        show: function(val) {
            let stringUWD = JSON.stringify(this.userWorkDay);
            if(val && stringUWD != JSON.stringify({})) {
                this.proposedUWD = JSON.parse(stringUWD);
            } else {
                this.proposedUWD = {
                    workDayRecords: [],
                    businessTrip: false,
                    overtimeMinutes: 0
                }
            }
        }
    },
    methods: {
        closeMethod: function() {
            this.$emit('close-consolidation-error-resolve-dialog');
        },
        sendUserWorkDayFix: function() {
            this.fixLoading = true;
            consolidationRecordsApi.fixConsolidationError(this.workDate, this.user.username, this.proposedUWD.overtimeMinutes, this.proposedUWD.businessTrip, this.proposedUWD.workDayRecords).then(response => {
                if(response.success) {
                    this.fixLoading = false;
                    this.$emit('refresh-consolidation-user-errors');
                    this.closeMethod();
                }
            })
        },
        formattedTime: function(timeISO) {
            if(timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE)
            } else {
                return null;
            }
        },
        editTimeRecord: function(workDayRecord) {
            this.editingRecord = JSON.parse(JSON.stringify(workDayRecord))
            this.timeRecordEditDialog = true;
        },
        storeEditedTimeRecord: function(timeRecordObject) {
            if(timeRecordObject.dateIndex != null && timeRecordObject.dateIndex != undefined) {
                let record = this.proposedUWD.workDayRecords.find(record => record.dateIndex == timeRecordObject.dateIndex);
                record.clockRecord.punchInTime = timeRecordObject.punchInTime;
                record.clockRecord.punchOutTime = timeRecordObject.punchOutTime;
                record.clockRecord.punchInTimeOffset = new Date(timeRecordObject.punchInTime).getTimezoneOffset();
                record.clockRecord.punchOutTimeOffset = new Date(timeRecordObject.punchOutTime).getTimezoneOffset();
            } else {
                let newIndex = Math.max(...(this.proposedUWD.workDayRecords.map(record => record.dateIndex)), -1) + 1;
                this.proposedUWD.workDayRecords.push({
                    dateIndex: newIndex,
                    recordType: "CLOCK_RECORD",
                    clockRecord: {
                        punchInTime: timeRecordObject.punchInTime,
                        punchOutTime: timeRecordObject.punchOutTime,
                        punchInTimeOffset: new Date(timeRecordObject.punchInTime).getTimezoneOffset(),
                        punchOutTimeOffset: new Date(timeRecordObject.punchOutTime).getTimezoneOffset(),
                    }
                })
            }
            this.timeRecordEditDialog = false;
            this.editingRecord = {};
        },
        createAbsenceRecord: function(workDayRecord) {
            this.editingRecord = JSON.parse(JSON.stringify(workDayRecord))
            this.absenceRecordCreateDialog = true;
        },
        registerLeaveRecord: function(minuteDuration, apply104) {
            let newIndex = Math.max(...(this.proposedUWD.workDayRecords.map(record => record.dateIndex)), -1) + 1;
            this.proposedUWD.workDayRecords.push({
                dateIndex: newIndex,
                recordType: 'LEAVE_RECORD',
                leaveRecord: {
                    minuteDuration: minuteDuration,
                    apply104: apply104
                }
            })
        },
        registerHolidayRecord: function(minuteDuration) {
            let newIndex = Math.max(...(this.proposedUWD.workDayRecords.map(record => record.dateIndex)), -1) + 1;
            this.proposedUWD.workDayRecords.push({
                dateIndex: newIndex,
                recordType: 'HOLIDAY_RECORD',
                holidayRecord: {
                    minuteDuration: minuteDuration,
                    fullDay: true
                }
            })
        },
        editAbsenceRecord: function(workDayRecord) {
            this.editingRecord = JSON.parse(JSON.stringify(workDayRecord))
            this.absenceRecordEditDialog = true;
        },
        updatingAbsenceRecord: function(updatePayload) {
            let recordIndex = this.proposedUWD.workDayRecords.findIndex(record => record.dateIndex == this.editingRecord.dateIndex);
            let record = {dateIndex: this.editingRecord.dateIndex};
            this.proposedUWD.workDayRecords.splice(recordIndex, 1);
            switch(updatePayload.absenceKind) {
            case 'leave':
                record.recordType = 'LEAVE_RECORD';
                record.leaveRecord = {
                    minuteDuration: updatePayload.minuteDuration,
                    apply104: updatePayload.apply104
                }
                break;
            case 'holiday':
                record.recordType = 'HOLIDAY_RECORD';
                record.holidayRecord = {
                    minuteDuration: updatePayload.minuteDuration
                }
                break;
            }
            this.proposedUWD.workDayRecords.splice(recordIndex, 0, record);
            this.absenceRecordEditDialog = false;
        },
        registerSickRecord: function() {
            if(this.proposedUWD.workDayRecords.length == 0 && !this.isBusinessTrip && !this.overtimeMinutes > 0) {
                this.proposedUWD.workDayRecords.push({
                    dateIndex: 0,
                    recordType: 'SICK_RECORD',
                    sickRecord: {
                        medicalCertificationDelivered: false
                    }
                })
            } else {
                console.log("cannot register a sick record");
            }
        },
        deletingRecord: function(dateIndex) {
            this.deletingRecordIndex = dateIndex;
            this.confirmDialogText = this.deletingRecordMessage;
            this.confirmDialogAction = this.deleteWorkDayRecord;
            this.confirmDialog = true;
        },
        deleteWorkDayRecord: function() {
            this.proposedUWD.workDayRecords = this.proposedUWD.workDayRecords.filter(record => record.dateIndex != this.deletingRecordIndex);
            this.confirmDialog = false;
        },
        setBusinessTrip: function() {
            this.proposedUWD.businessTrip = !(this.proposedUWD.businessTrip || false);
        },
        updateOvertime: function(updatedOvertimeMinutes) {
            this.proposedUWD.overtimeMinutes = updatedOvertimeMinutes;
        },
        updateTimeProgressObject: function(tpObject) {
            this.timerProgressObject = tpObject;
        }
    },         
    computed: {
        overtimeString: function() {
            let otm = this.overtimeMinutes;
            return (otm >= 60 ? Math.floor(otm/60) + 'h ' : '') + (otm % 60 != 0 ? otm % 60 + 'm': '').trim();
        },
        isEmptyUWD: function() {
            return (this.proposedUWD.workDayRecords || []).length == 0;
        },
        isSickDay: function() {
            return !!(this.proposedUWD.workDayRecords || []).find(record => record.recordType == 'SICK_RECORD');
        },
        isHolidayDay: function() {
            return !!(this.proposedUWD.workDayRecords || []).find(record => {
                return record.recordType == 'HOLIDAY_RECORD' && record.holidayRecord && record.holidayRecord.fullDay
            });
        },
        isBusinessTrip: function() {
            return this.proposedUWD.businessTrip || false;
        },
        overtimeMinutes: function() {
            return this.proposedUWD.overtimeMinutes || 0;
        },
        userWorkDay: function() {
            return (this.error.userWorkDay || {});
        },
        workDayRecords: function() {
            return (this.proposedUWD.workDayRecords || []);
        },
        wrappedWorkDate: function() {
            return DateTime.fromJSDate(this.jsWorkDate); 
        },
        formattedWorkDateObject: function() {
            return {
                day: this.wrappedWorkDate.setLocale('it').toFormat('d'),
                month: this.wrappedWorkDate.setLocale('it').toFormat('LLLL'),
                year: this.wrappedWorkDate.setLocale('it').toFormat('y')
            }
        },
        jsWorkDate: function() {
            return new Date(this.workDate);
        },
        workDate: function() {
            return this.error.workDate;
        },
        user: function() {
            return ((this.userErrors || {}).user || {});
        },
        workDayTimes: function() {
            return (this.error.dateWorkTimes || {});
        },
        morningTimesPresent: function() {
            return !!this.error.dateWorkTimes.morningTimes.clockInTime && !!this.error.dateWorkTimes.morningTimes.clockOutTime;
        },
        afternoonTimesPresent: function() {
            return !!this.error.dateWorkTimes.afternoonTimes.clockInTime && !!this.error.dateWorkTimes.afternoonTimes.clockOutTime;
        },
        userConsolidationErrors: function() {
            return ((this.userErrors || {}).consolidationErrors || []);
        },
        error: function() {
            if(this.resolvingErrorIndex >= 0) {
                return (this.userConsolidationErrors[this.resolvingErrorIndex] || {});
            }
            return {};
        },
        modalDataObject: function() {
            let color = '';
            let title = '';
            switch (this.error.errorCode) {
                case 'REQUIRED_TIMES_NOT_COVERED':
                    color = 'red lighten-2';
                    title = 'Orario Pianificato non coperto';
                    break;
                case 'UNNECESSARY_ABSENCE_TIMES':
                    color = 'light-blue lighten-3';
                    title = 'Minuti di Assenza non necessari';
                    break;
                case 'PENDING_VARIATION_PROPOSAL':
                    color = 'orange lighten-3';
                    title = 'Proposta di Modifica in attesa';
                    break;
                default:
                    color = 'red lighten-2';
                    title = '';
            }

            return {
                color: color,
                title: title
            }

        },
        clockRecords: function() {
            return this.workDayRecords.filter(wdr => wdr.recordType == 'CLOCK_RECORD')
        },
        clockRecordTimes: function() {
            return this.clockRecords.map(record => {
                return {
                punchInTime: record.clockRecord.punchInTime,
                punchOutTime: record.clockRecord.punchOutTime,
                dateIndex: record.dateIndex,
                }
            });
        },
        deletingRecordMessage: function() {
            let record = this.workDayRecords.find(record => record.dateIndex == this.deletingRecordIndex) || {};
            switch(record.recordType) {
                case 'LEAVE_RECORD': 
                    return 'Eliminare il Permesso?';
                case 'HOLIDAY_RECORD': 
                    return 'Eliminare le Ferie?';
                case 'SICK_RECORD': 
                    return 'Eliminare la Malattia?';
                case 'CLOCK_RECORD': 
                    return 'Eliminare la Timbratura?';
                default:
                    return '';
            }
        },
        workDaySummary: function() {
            if(this.workDate && this.workDayTimes && this.workDayRecords) {
                let summary = this.userWorkDaySummary(this.workDayRecords, this.workDayTimes, this.workDate, this.isBusinessTrip);
                if(this.isBusinessTrip)
                    summary.registeredMinutes = summary.clockMinutes;
                return summary;
            }
            return {};
           
        },
        dayTimeProgressObject: function() {
            let percentage = Math.min(Math.floor((((this.isBusinessTrip ? this.workDaySummary.clockMinutes : this.workDaySummary.registeredMinutes) + this.workDaySummary.absenceMinutes) / this.workDaySummary.scheduledMinutes) * 100), 100);
            return {
                progressPercentage: percentage,
                registeredTime: (this.isBusinessTrip ? this.workDaySummary.clockMinutes : this.workDaySummary.registeredMinutes) + this.workDaySummary.absenceMinutes,
                scheduledTime: this.workDaySummary.scheduledMinutes,
                complete: percentage >= 100 || this.isSickDay || this.isHolidayDay,
            };
        },
    }
}
</script>